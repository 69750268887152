import React, { lazy } from 'react';
import { demoPages, mainMenu, administrationMenu, libraryMenu } from '../menu';

const MAIN = {
	MAIN: lazy(() => import('../pages/dashboard/MainPage')),
	TASKS: lazy(() => import('../pages/tasks/TasksPage')),
	TASK_ITEM: lazy(() => import('../pages/tasks/TaskPage')),
	BOOK: lazy(() => import('../pages/users/BookPage')),
	PROJECTS: {
		MAIN: lazy(() => import('../pages/projects/MainPage')),
		PROJECT: lazy(() => import('../pages/projects/ProjectPage')),
		FILES: lazy(() => import('../pages/projects/FilesPage')),
		PROJECT_CHART: lazy(() => import('../pages/projects/ChartPage')),
		FILE_VIEW: lazy(() => import('../pages/projects/ViewPage')),
	},
	SHARE: {
		PROJECTS: lazy(() => import('../pages/share/projects/SharePage')),
		FILE_VIEW: lazy(() => import('../pages/share/projects/ShareFileView')),
	},
	PROFILE: lazy(() => import('../pages/users/ProfilePage')),
	AGREEMENTS: lazy(() => import('../pages/agreements/AgreementsPage')),
	AGREEMENT: lazy(() => import('../pages/agreements/AgreementPage')),
	CONTRACTORS: lazy(() => import('../pages/documents/contractors/ContractorsPage')),
	CORRESPONDENCE: lazy(() => import('../pages/documents/CorrespondencePage')),
	DOCUMENT: lazy(() => import('../pages/documents/DocumentPage')),
	INVENTARIZATION: lazy(() => import('../pages/inventarization/InventarizationPage')),
	FLEET: {
		MAIN: lazy(() => import('../pages/fleet/FleetPage')),
		VEHICLE: lazy(() => import('../pages/fleet/VehiclePage')),
		FINE: lazy(() => import('../pages/fleet/FinePage')),
	},
	WORKTIME: lazy(() => import('../pages/reports/WorktimePage')),
};

const LIBRARY = {
	PLACES: lazy(() => import('../pages/library/PlacesPage')),
};

const ADMINISTRATION = {
	API: lazy(() => import('../pages/api/Main')),
	USERS: lazy(() => import('../pages/administration/UsersPage')),
	PROFILE: lazy(() => import('../pages/administration/UserProfilePage')),
};

// const LANDING = {
// 	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
// 	DASHBOARD_BOOKING: lazy(() => import('../pages/dashboard/DashboardBookingPage')),
// 	SUMMARY: lazy(() => import('../pages/SummaryPage')),
// };
// const SINGLE = {
// 	BOXED: lazy(() => import('../pages/presentation/single-pages/SingleBoxedPage')),
// 	FLUID: lazy(() => import('../pages/presentation/single-pages/SingleFluidPage')),
// };
// const LIST = {
// 	BOXED: lazy(() => import('../pages/presentation/demo-pages/ListBoxedPage')),
// 	FLUID: lazy(() => import('../pages/presentation/demo-pages/ListFluidPage')),
// };
// const GRID = {
// 	BOXED: lazy(() => import('../pages/presentation/demo-pages/GridBoxedPage')),
// 	FLUID: lazy(() => import('../pages/presentation/demo-pages/GridFluidPage')),
// };
// const EDIT = {
// 	BOXED: lazy(() => import('../pages/presentation/demo-pages/EditBoxedPage')),
// 	FLUID: lazy(() => import('../pages/presentation/demo-pages/EditFluidPage')),
// 	WIZARD: lazy(() => import('../pages/presentation/demo-pages/EditWizardPage')),
// 	IN_CANVAS: lazy(() => import('../pages/presentation/demo-pages/EditInCanvasPage')),
// 	IN_MODAL: lazy(() => import('../pages/presentation/demo-pages/EditInModalPage')),
// };
// const PRICING = {
// 	PRICING_TABLE: lazy(() => import('../pages/presentation/pricing/PricingTablePage')),
// };

const AUTH = {
	LOGIN: lazy(() => import('../pages/presentation/auth/Login')),
	SIGNUP: lazy(() => import('../pages/presentation/auth/SignUp')),
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
// const APP = {
// 	PROJECT_MANAGEMENT: {
// 		PROJECTS_LIST: lazy(() =>
// 			import('../pages/presentation/project-management/ProjectManagementsList'),
// 		),
// 		PROJECT: lazy(() =>
// 			import('../pages/presentation/project-management/ProjectManagementsProject'),
// 		),
// 	},
// 	KNOWLEDGE: {
// 		GRID: lazy(() => import('../pages/presentation/knowledge/KnowledgeGridPage')),
// 		VIEW: lazy(() => import('../pages/presentation/knowledge/KnowledgeViewPage')),
// 	},
// 	SALES: {
// 		TRANSACTIONS: lazy(() => import('../pages/presentation/sales/TransActionsPage')),
// 		PRODUCTS: lazy(() => import('../pages/presentation/sales/SalesListPage')),
// 		PRODUCTS_GRID: lazy(() => import('../pages/presentation/sales/ProductsGridPage')),
// 		PRODUCTS_VIEW: lazy(() => import('../pages/presentation/sales/ProductViewPage')),
// 	},
// 	APPOINTMENT: {
// 		CALENDAR: lazy(() => import('../pages/presentation/appointment/CalendarPage')),
// 		EMPLOYEE_LIST: lazy(() => import('../pages/presentation/appointment/EmployeeList')),
// 		EMPLOYEE_VIEW: lazy(() => import('../pages/presentation/appointment/EmployeePage')),
// 		APPOINTMENT_LIST: lazy(() => import('../pages/presentation/appointment/AppointmentList')),
// 	},
// 	CRM: {
// 		CRM_DASHBOARD: lazy(() => import('../pages/presentation/crm/CrmDashboard')),
// 		CUSTOMERS: lazy(() => import('../pages/presentation/crm/CustomersList')),
// 		CUSTOMER: lazy(() => import('../pages/presentation/crm/Customer')),
// 		SALES: lazy(() => import('../pages/presentation/crm/Sales')),
// 		INVOICE: lazy(() => import('../pages/presentation/crm/Invoice')),
// 	},
// 	CHAT: {
// 		WITH_LIST: lazy(() => import('../pages/presentation/chat/WithListChatPage')),
// 		ONLY_LIST: lazy(() => import('../pages/presentation/chat/OnlyListChatPage')),
// 	},
// };
// const PAGE_LAYOUTS = {
// 	// HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
// 	// HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
// 	// SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
// 	// CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
// 	// BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
// 	// ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
// 	// MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
// };

// const CONTENT = {
// 	CONTENTS: lazy(() => import('../pages/documentation/content/ContentListPage')),
// 	TYPOGRAPHY: lazy(() => import('../pages/documentation/content/TypographyPage')),
// 	IMAGES: lazy(() => import('../pages/documentation/content/ImagesPage')),
// 	TABLES: lazy(() => import('../pages/documentation/content/TablesPage')),
// 	FIGURES: lazy(() => import('../pages/documentation/content/FiguresPage')),
// };
// const FORMS_PAGE = {
// 	FORMS: lazy(() => import('../pages/documentation/forms/FormsListPage')),
// 	FORM_GROUP: lazy(() => import('../pages/documentation/forms/FormGroupPage')),
// 	FORM_CONTROLS: lazy(() => import('../pages/documentation/forms/FormControlsPage')),
// 	SELECT: lazy(() => import('../pages/documentation/forms/SelectPage')),
// 	CHECKS_AND_RADIO: lazy(() => import('../pages/documentation/forms/ChecksAndRadioPage')),
// 	RANGE: lazy(() => import('../pages/documentation/forms/RangePage')),
// 	INPUT_GROUP: lazy(() => import('../pages/documentation/forms/InputGroupPage')),
// 	VALIDATION: lazy(() => import('../pages/documentation/forms/ValidationPage')),
// 	WIZARD: lazy(() => import('../pages/documentation/forms/WizardPage')),
// };
// const COMPONENTS_PAGE = {
// 	COMPONENTS: lazy(() => import('../pages/documentation/components/ComponentsListPage')),
// 	ACCORDION: lazy(() => import('../pages/documentation/components/AccordionPage')),
// 	ALERT: lazy(() => import('../pages/documentation/components/AlertPage')),
// 	BADGE: lazy(() => import('../pages/documentation/components/BadgePage')),
// 	BREADCRUMB: lazy(() => import('../pages/documentation/components/BreadcrumbPage')),
// 	BUTTON: lazy(() => import('../pages/documentation/components/ButtonPage')),
// 	BUTTON_GROUP: lazy(() => import('../pages/documentation/components/ButtonGroupPage')),
// 	CARD: lazy(() => import('../pages/documentation/components/CardPage')),
// 	CAROUSEL: lazy(() => import('../pages/documentation/components/CarouselPage')),
// 	COLLAPSE: lazy(() => import('../pages/documentation/components/CollapsePage')),
// 	DROPDOWN: lazy(() => import('../pages/documentation/components/DropdownsPage')),
// 	LIST_GROUP: lazy(() => import('../pages/documentation/components/ListGroupPage')),
// 	MODAL: lazy(() => import('../pages/documentation/components/ModalPage')),
// 	NAVS_TABS: lazy(() => import('../pages/documentation/components/NavsTabsPage')),
// 	OFF_CANVAS: lazy(() => import('../pages/documentation/components/OffCanvasPage')),
// 	PAGINATION: lazy(() => import('../pages/documentation/components/PaginationPage')),
// 	POPOVERS: lazy(() => import('../pages/documentation/components/PopoversPage')),
// 	PROGRESS: lazy(() => import('../pages/documentation/components/ProgressPage')),
// 	SCROLLSPY: lazy(() => import('../pages/documentation/components/ScrollspyPage')),
// 	SPINNER: lazy(() => import('../pages/documentation/components/SpinnersPage')),
// 	TABLE: lazy(() => import('../pages/documentation/components/TablePage')),
// 	TOASTS: lazy(() => import('../pages/documentation/components/ToastsPage')),
// 	TOOLTIP: lazy(() => import('../pages/documentation/components/TooltipPage')),
// };
// const UTILITIES = {
// 	UTILITIES: lazy(() => import('../pages/documentation/utilities/UtilitiesListPage')),
// 	API: lazy(() => import('../pages/documentation/utilities/ApiPage')),
// 	BACKGROUND: lazy(() => import('../pages/documentation/utilities/BackgroundPage')),
// 	BORDERS: lazy(() => import('../pages/documentation/utilities/BordersPage')),
// 	COLORS: lazy(() => import('../pages/documentation/utilities/ColorsPage')),
// 	DISPLAY: lazy(() => import('../pages/documentation/utilities/DisplayPage')),
// 	FLEX: lazy(() => import('../pages/documentation/utilities/FlexPage')),
// 	FLOAT: lazy(() => import('../pages/documentation/utilities/FloatPage')),
// 	INTERACTIONS: lazy(() => import('../pages/documentation/utilities/InteractionsPage')),
// 	OVERFLOW: lazy(() => import('../pages/documentation/utilities/OverflowPage')),
// 	POSITION: lazy(() => import('../pages/documentation/utilities/PositionPage')),
// 	SHADOWS: lazy(() => import('../pages/documentation/utilities/ShadowsPage')),
// 	SIZING: lazy(() => import('../pages/documentation/utilities/SizingPage')),
// 	SPACING: lazy(() => import('../pages/documentation/utilities/SpacingPage')),
// 	TEXT: lazy(() => import('../pages/documentation/utilities/TextPage')),
// 	VERTICAL_ALIGN: lazy(() => import('../pages/documentation/utilities/VerticalAlignPage')),
// 	VISIBILITY: lazy(() => import('../pages/documentation/utilities/VisibilityPage')),
// };
// const ICONS = {
// 	ICONS_LIST: lazy(() => import('../pages/documentation/icons/IconsListPage')),
// 	ICON: lazy(() => import('../pages/documentation/icons/IconPage')),
// 	BOOTSTRAP: lazy(() => import('../pages/documentation/icons/BootstrapIconPage')),
// 	MATERIAL: lazy(() => import('../pages/documentation/icons/MaterialPage')),
// };
// const CHARTS_PAGE = {
// 	CHART_LIST: lazy(() => import('../pages/documentation/charts/ChartsListPage')),
// 	GENERAL_USAGE: lazy(() => import('../pages/documentation/charts/ChartGeneralUsagePage')),
// 	SPARKLINE: lazy(() => import('../pages/documentation/charts/ChartSparklinePage')),
// 	LINE: lazy(() => import('../pages/documentation/charts/ChartLinePage')),
// 	AREA: lazy(() => import('../pages/documentation/charts/ChartAreaPage')),
// 	COLUMN: lazy(() => import('../pages/documentation/charts/ChartColumnPage')),
// 	BAR: lazy(() => import('../pages/documentation/charts/ChartBarPage')),
// 	MIXED: lazy(() => import('../pages/documentation/charts/ChartMixedPage')),
// 	TIMELINE: lazy(() => import('../pages/documentation/charts/ChartTimelinePage')),
// 	CANDLESTICK: lazy(() => import('../pages/documentation/charts/ChartCandlestickPage')),
// 	BOX_WHISKER: lazy(() => import('../pages/documentation/charts/ChartBoxWhiskerPage')),
// 	PIE_DONUT: lazy(() => import('../pages/documentation/charts/ChartPieDonutPage')),
// 	RADAR: lazy(() => import('../pages/documentation/charts/ChartRadarPage')),
// 	POLAR: lazy(() => import('../pages/documentation/charts/ChartPolarPage')),
// 	RADIAL_BAR: lazy(() => import('../pages/documentation/charts/ChartRadialBarPage')),
// 	BUBBLE: lazy(() => import('../pages/documentation/charts/ChartBubblePage')),
// 	SCATTER: lazy(() => import('../pages/documentation/charts/ChartScatterPage')),
// 	HEAT_MAP: lazy(() => import('../pages/documentation/charts/ChartHeatMapPage')),
// 	TREE_MAP: lazy(() => import('../pages/documentation/charts/ChartTreeMapPage')),
// };
// const EXTRA = {
// 	NOTIFICATION: lazy(() => import('../pages/documentation/extras/NotificationPage')),
// 	HOOKS: lazy(() => import('../pages/documentation/extras/HooksPage')),
// };

// const presentation = [
// 	/**
// 	 * Landing
// 	 */
// 	{
// 		path: dashboardMenu.dashboard.path,
// 		element: <LANDING.DASHBOARD />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: dashboardMenu.dashboardBooking.path,
// 		element: <LANDING.DASHBOARD_BOOKING />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: dashboardMenu.summary.path,
// 		element: <LANDING.SUMMARY />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/** ************************************************** */

// 	/**
// 	 * Pages
// 	 */

// 	/**
// 	 * Single Pages
// 	 */
// 	{
// 		path: demoPages.singlePages.subMenu.boxedSingle.path,
// 		element: <SINGLE.BOXED />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.singlePages.subMenu.fluidSingle.path,
// 		element: <SINGLE.FLUID />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * List
// 	 */
// 	{
// 		path: demoPages.listPages.subMenu.listBoxed.path,
// 		element: <LIST.BOXED />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.listPages.subMenu.listFluid.path,
// 		element: <LIST.FLUID />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * Grid
// 	 */
// 	{
// 		path: demoPages.gridPages.subMenu.gridBoxed.path,
// 		element: <GRID.BOXED />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.gridPages.subMenu.gridFluid.path,
// 		element: <GRID.FLUID />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * Edit
// 	 */
// 	{
// 		path: demoPages.editPages.subMenu.editBoxed.path,
// 		element: <EDIT.BOXED />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.editPages.subMenu.editFluid.path,
// 		element: <EDIT.FLUID />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.editPages.subMenu.editWizard.path,
// 		element: <EDIT.WIZARD />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.editPages.subMenu.editInCanvas.path,
// 		element: <EDIT.IN_CANVAS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.editPages.subMenu.editInModal.path,
// 		element: <EDIT.IN_MODAL />,
// 		exact: true,
// 		auth: true,
// 	},

// 	{
// 		path: demoPages.pricingTable.path,
// 		element: <PRICING.PRICING_TABLE />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * END - Pages
// 	 */

// 	/**
// 	 * Auth Page
// 	 */
// 	{
// 		path: demoPages.page404.path,
// 		element: <AUTH.PAGE_404 />,
// 		exact: true,
// 		auth: false,
// 	},
// 	{
// 		path: demoPages.login.path,
// 		element: <Login />,
// 		exact: true,
// 		auth: false,
// 	},

// 	/**
// 	 * App
// 	 */

// 	/**
// 	 * App > Project Management
// 	 */
// 	{
// 		path: demoPages.projectManagement.subMenu.list.path,
// 		element: <APP.PROJECT_MANAGEMENT.PROJECTS_LIST />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: `${demoPages.projectManagement.subMenu.itemID.path}/:id`,
// 		element: <APP.PROJECT_MANAGEMENT.PROJECT />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * App > Knowledge
// 	 */
// 	{
// 		path: demoPages.knowledge.subMenu.grid.path,
// 		element: <APP.KNOWLEDGE.GRID />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: `${demoPages.knowledge.subMenu.itemID.path}/:id`,
// 		element: <APP.KNOWLEDGE.VIEW />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * App > Sales
// 	 */
// 	{
// 		path: demoPages.sales.subMenu.transactions.path,
// 		element: <APP.SALES.TRANSACTIONS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.sales.subMenu.salesList.path,
// 		element: <APP.SALES.PRODUCTS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.sales.subMenu.productsGrid.path,
// 		element: <APP.SALES.PRODUCTS_GRID />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: `${demoPages.sales.subMenu.productID.path}/:id`,
// 		element: <APP.SALES.PRODUCTS_VIEW />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * App > Appointment
// 	 */
// 	{
// 		path: demoPages.appointment.subMenu.calendar.path,
// 		element: <APP.APPOINTMENT.CALENDAR />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.appointment.subMenu.employeeList.path,
// 		element: <APP.APPOINTMENT.EMPLOYEE_LIST />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: `${demoPages.appointment.subMenu.employeeID.path}/:id`,
// 		element: <APP.APPOINTMENT.EMPLOYEE_VIEW />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.appointment.subMenu.appointmentList.path,
// 		element: <APP.APPOINTMENT.APPOINTMENT_LIST />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * App > CRM
// 	 */
// 	{
// 		path: demoPages.crm.subMenu.dashboard.path,
// 		element: <APP.CRM.CRM_DASHBOARD />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.crm.subMenu.customersList.path,
// 		element: <APP.CRM.CUSTOMERS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: `${demoPages.crm.subMenu.customerID.path}/:id`,
// 		element: <APP.CRM.CUSTOMER />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * App > Chat
// 	 */
// 	{
// 		path: demoPages.chat.subMenu.withListChat.path,
// 		element: <APP.CHAT.WITH_LIST />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: demoPages.chat.subMenu.onlyListChat.path,
// 		element: <APP.CHAT.ONLY_LIST />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * END - App
// 	 */

// 	/** ************************************************** */

// 	/**
// 	 * Page Layout Types
// 	 */
// 	{
// 		path: layoutMenu.blank.path,
// 		element: <PAGE_LAYOUTS.BLANK />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
// 		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
// 		element: <PAGE_LAYOUTS.HEADER />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
// 		element: <PAGE_LAYOUTS.SUBHEADER />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
// 		element: <PAGE_LAYOUTS.CONTENT />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: layoutMenu.asideTypes.subMenu.defaultAside.path,
// 		element: <PAGE_LAYOUTS.ASIDE />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
// 		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
// 		exact: true,
// 		auth: true,
// 	},
// ];
// const documentation = [
// 	/**
// 	 * Bootstrap
// 	 */

// 	/**
// 	 * Content
// 	 */
// 	{
// 		path: componentsMenu.content.path,
// 		element: <CONTENT.CONTENTS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.content.subMenu.typography.path,
// 		element: <CONTENT.TYPOGRAPHY />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.content.subMenu.images.path,
// 		element: <CONTENT.IMAGES />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.content.subMenu.tables.path,
// 		element: <CONTENT.TABLES />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.content.subMenu.figures.path,
// 		element: <CONTENT.FIGURES />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * Forms
// 	 */
// 	{
// 		path: componentsMenu.forms.path,
// 		element: <FORMS_PAGE.FORMS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.formGroup.path,
// 		element: <FORMS_PAGE.FORM_GROUP />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.formControl.path,
// 		element: <FORMS_PAGE.FORM_CONTROLS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.select.path,
// 		element: <FORMS_PAGE.SELECT />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.checksAndRadio.path,
// 		element: <FORMS_PAGE.CHECKS_AND_RADIO />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.range.path,
// 		element: <FORMS_PAGE.RANGE />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.inputGroup.path,
// 		element: <FORMS_PAGE.INPUT_GROUP />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.validation.path,
// 		element: <FORMS_PAGE.VALIDATION />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.wizard.path,
// 		element: <FORMS_PAGE.WIZARD />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * Components
// 	 */
// 	{
// 		path: componentsMenu.components.path,
// 		element: <COMPONENTS_PAGE.COMPONENTS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.tooltip.path,
// 		element: <COMPONENTS_PAGE.TOOLTIP />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.toasts.path,
// 		element: <COMPONENTS_PAGE.TOASTS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.scrollspy.path,
// 		element: <COMPONENTS_PAGE.SCROLLSPY />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.carousel.path,
// 		element: <COMPONENTS_PAGE.CAROUSEL />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.spinners.path,
// 		element: <COMPONENTS_PAGE.SPINNER />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.listGroup.path,
// 		element: <COMPONENTS_PAGE.LIST_GROUP />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.breadcrumb.path,
// 		element: <COMPONENTS_PAGE.BREADCRUMB />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.collapse.path,
// 		element: <COMPONENTS_PAGE.COLLAPSE />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.pagination.path,
// 		element: <COMPONENTS_PAGE.PAGINATION />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.progress.path,
// 		element: <COMPONENTS_PAGE.PROGRESS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.card.path,
// 		element: <COMPONENTS_PAGE.CARD />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.button.path,
// 		element: <COMPONENTS_PAGE.BUTTON />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.buttonGroup.path,
// 		element: <COMPONENTS_PAGE.BUTTON_GROUP />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.alert.path,
// 		element: <COMPONENTS_PAGE.ALERT />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.badge.path,
// 		element: <COMPONENTS_PAGE.BADGE />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.popovers.path,
// 		element: <COMPONENTS_PAGE.POPOVERS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.dropdowns.path,
// 		element: <COMPONENTS_PAGE.DROPDOWN />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.accordion.path,
// 		element: <COMPONENTS_PAGE.ACCORDION />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.modal.path,
// 		element: <COMPONENTS_PAGE.MODAL />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.navsTabs.path,
// 		element: <COMPONENTS_PAGE.NAVS_TABS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.offcanvas.path,
// 		element: <COMPONENTS_PAGE.OFF_CANVAS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.table.path,
// 		element: <COMPONENTS_PAGE.TABLE />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * Utilities
// 	 */
// 	{
// 		path: componentsMenu.utilities.path,
// 		element: <UTILITIES.UTILITIES />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.api.path,
// 		element: <UTILITIES.API />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.background.path,
// 		element: <UTILITIES.BACKGROUND />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.borders.path,
// 		element: <UTILITIES.BORDERS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.colors.path,
// 		element: <UTILITIES.COLORS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.display.path,
// 		element: <UTILITIES.DISPLAY />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.flex.path,
// 		element: <UTILITIES.FLEX />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.float.path,
// 		element: <UTILITIES.FLOAT />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.interactions.path,
// 		element: <UTILITIES.INTERACTIONS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.overflow.path,
// 		element: <UTILITIES.OVERFLOW />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.position.path,
// 		element: <UTILITIES.POSITION />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.shadows.path,
// 		element: <UTILITIES.SHADOWS />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.sizing.path,
// 		element: <UTILITIES.SIZING />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.spacing.path,
// 		element: <UTILITIES.SPACING />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.text.path,
// 		element: <UTILITIES.TEXT />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.verticalAlign.path,
// 		element: <UTILITIES.VERTICAL_ALIGN />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.visibility.path,
// 		element: <UTILITIES.VISIBILITY />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * Extra
// 	 */

// 	/**
// 	 * Icons
// 	 */
// 	{
// 		path: componentsMenu.icons.path,
// 		element: <ICONS.ICONS_LIST />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.icons.subMenu.icon.path,
// 		element: <ICONS.ICON />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.icons.subMenu.material.path,
// 		element: <ICONS.MATERIAL />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.icons.subMenu.bootstrapIcon.path,
// 		element: <ICONS.BOOTSTRAP />,
// 		exact: true,
// 		auth: true,
// 	},

// 	/**
// 	 * Charts
// 	 */
// 	{
// 		path: componentsMenu.charts.path,
// 		element: <CHARTS_PAGE.CHART_LIST />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsUsage.path,
// 		element: <CHARTS_PAGE.GENERAL_USAGE />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsSparkline.path,
// 		element: <CHARTS_PAGE.SPARKLINE />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsLine.path,
// 		element: <CHARTS_PAGE.LINE />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsArea.path,
// 		element: <CHARTS_PAGE.AREA />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsColumn.path,
// 		element: <CHARTS_PAGE.COLUMN />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsBar.path,
// 		element: <CHARTS_PAGE.BAR />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsMixed.path,
// 		element: <CHARTS_PAGE.MIXED />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsTimeline.path,
// 		element: <CHARTS_PAGE.TIMELINE />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsCandleStick.path,
// 		element: <CHARTS_PAGE.CANDLESTICK />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsBoxWhisker.path,
// 		element: <CHARTS_PAGE.BOX_WHISKER />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsPieDonut.path,
// 		element: <CHARTS_PAGE.PIE_DONUT />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsRadar.path,
// 		element: <CHARTS_PAGE.RADAR />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsPolar.path,
// 		element: <CHARTS_PAGE.POLAR />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsRadialBar.path,
// 		element: <CHARTS_PAGE.RADIAL_BAR />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsBubble.path,
// 		element: <CHARTS_PAGE.BUBBLE />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsScatter.path,
// 		element: <CHARTS_PAGE.SCATTER />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsHeatMap.path,
// 		element: <CHARTS_PAGE.HEAT_MAP />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsTreeMap.path,
// 		element: <CHARTS_PAGE.TREE_MAP />,
// 		exact: true,
// 		auth: true,
// 	},

// 	{
// 		path: componentsMenu.notification.path,
// 		element: <EXTRA.NOTIFICATION />,
// 		exact: true,
// 		auth: true,
// 	},
// 	{
// 		path: componentsMenu.hooks.path,
// 		element: <EXTRA.HOOKS />,
// 		exact: true,
// 		auth: true,
// 	},
// ];

const auth = [
	/**
	 * Auth Page
	 */
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
		auth: false,
	},
	{
		path: demoPages.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
		auth: false,
	},
	{
		path: demoPages.signUp.path,
		element: <AUTH.SIGNUP />,
		exact: true,
		auth: true,
	},
	{
		path: `${demoPages.signUp.path}/:hash`,
		element: <AUTH.SIGNUP />,
		exact: true,
		auth: false,
	},
];

const main = [
	{
		path: mainMenu.tasks.path,
		element: <MAIN.TASKS />,
		exact: true,
		auth: true,
	},
	{
		path: `${mainMenu.tasks.path}/:hash`,
		element: <MAIN.TASK_ITEM />,
		exact: true,
		auth: true,
	},
	{
		path: `${mainMenu.tasks.path}/:hash/files/:file`,
		element: <MAIN.TASK_ITEM />,
		exact: true,
		auth: true,
	},
	{
		path: mainMenu.dashboard.path,
		element: <MAIN.MAIN />,
		exact: true,
		auth: true,
	},
	{
		path: mainMenu.book.path,
		element: <MAIN.BOOK />,
		exact: true,
		auth: true,
	},
	{
		path: 'profile',
		element: <MAIN.PROFILE />,
		exact: true,
		auth: true,
	},
];

const library = [
	{
		path: libraryMenu.library.subMenu.places.path,
		element: <LIBRARY.PLACES />,
		exact: true,
		auth: true,
		isAdmin: true,
	},
];

const administration = [
	{
		path: administrationMenu.administration.subMenu.administrationUsers.path,
		element: <ADMINISTRATION.USERS />,
		exact: true,
		auth: true,
		isAdmin: true,
	},
	{
		path: `${administrationMenu.administration.subMenu.administrationUsers.path}/:hash/*`,
		element: <ADMINISTRATION.PROFILE />,
		exact: true,
		auth: true,
		isAdmin: true,
	},
	{
		path: administrationMenu.administration.subMenu.administrationApi.path,
		element: <ADMINISTRATION.API />,
		exact: true,
		auth: true,
		isAdmin: true,
	},
];

const contents = [...auth, ...main, ...library, ...administration];

export default contents;
