import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useNavigate } from 'react-router-dom';

import { AvatarStorage } from '../../../components/Avatar';
import Dropdown, {
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import { useAuth } from '../../../contexts/authContext';
import { useDevContext } from '../../../contexts/devContext';

const CommonHeaderChat = () => {
	const navigate = useNavigate();
	const [messages, setMessages] = useState(0);
	const auth = useAuth();
	const { isDev } = useDevContext();

	const { lastJsonMessage } = useWebSocket(
		isDev ? 'wss://wss-test.bsrc.ru' : process.env.REACT_APP_WEBSOCKET_URL,
		{
			shouldReconnect: () => true,
			reconnectAttempts: Infinity,
			reconnectInterval: 100000,
			share: true,
			filter: (message) => message.type === 'notifications',
		},
	);

	useEffect(() => {
		if (lastJsonMessage !== null) {
			setMessages(messages + 1);
		}
	}, [messages, lastJsonMessage]);

	return (
		<Dropdown isOpen direction='down' className='col'>
			<DropdownToggle hasIcon={false}>
				<Button>
					<div className='d-flex align-items-center cursor-pointer'>
						<div className='me-3'>
							<div className='text-end'>
								<div className='fw-bold fs-6 mb-0'>{`${auth.user?.user.name} ${auth.user?.user.surname}`}</div>
								<div className='text-muted' style={{ maxWidth: '320px' }}>
									<small className='text-wrap'>{auth.user?.user.post}</small>
								</div>
							</div>
						</div>
						<div className='position-relative'>
							{auth.user?.user.images ? (
								<AvatarStorage
									images={auth.user.user.images}
									size={48}
									color={auth.user?.user.color}
								/>
							) : null}
							{!!messages && (
								<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
									{messages} <span className='visually-hidden'>непрочитанных сообщений</span>
								</span>
							)}
						</div>
					</div>
				</Button>
			</DropdownToggle>
			<DropdownMenu isAlignmentEnd>
				<DropdownItem
					onClick={() => {
						navigate('/profile');
					}}>
					<div className='text-nowrap overflow-hidden text-overflow-ellipsis'>
						<Icon icon='AccountCircle' />
						<span>Профиль</span>
					</div>
				</DropdownItem>
				<DropdownItem onClick={auth.handleLogout}>
					<div className='text-nowrap overflow-hidden text-overflow-ellipsis'>
						<Icon icon='Logout' />
						<span>Выход</span>
					</div>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default CommonHeaderChat;
