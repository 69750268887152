import React, { useCallback, Fragment } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import CommonHeaderChat from './CommonHeaderChat';
import useDarkMode from '../../../hooks/useDarkMode';
import Spinner from '../../../components/bootstrap/Spinner';
import Popovers from '../../../components/bootstrap/Popovers';
import Button from '../../../components/bootstrap/Button';
import Checks from '../../../components/bootstrap/forms/Checks';
import ReactSelect from '../../../components/extras/ReactSelect';

import { useAuth } from '../../../contexts/authContext';
import { useLoadingContext } from '../../../contexts/loadingContext';
import { useDevContext } from '../../../contexts/devContext';
import useFetch from '../../../hooks/useFetch';

const DefaultHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const auth = useAuth();
	const { isLoading } = useLoadingContext();
	const { sendData } = useFetch();
	const [, setSearchParams] = useSearchParams();
	const { isDev, setIsDev } = useDevContext();

	const onUserSelect = useCallback(
		(value) => {
			if (value) {
				const formData = new FormData();
				formData.set('token', auth.token);
				formData.set('user', value.hash);
				sendData(`${process.env.REACT_APP_API_URL}/admin/login/`, formData).then((responseData) => {
					if (responseData?.success === 'Auth ok' && responseData?.session_id) {
						setSearchParams({
							token: responseData.session_id,
						});
					}
				});
			}
		},
		[auth.token, sendData, setSearchParams],
	);

	return (
		<Header hasLeftMobileMenu={false}>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div
								className={classNames('fw-bold', {
									'text-dark': !darkModeStatus,
								})}>
								{isLoading ? (
									<Popovers title='Загрузка...' trigger='hover'>
										<Spinner />
									</Popovers>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			<CommonHeaderRight
				beforeChildren={
					auth.user?.user.isAdmin ? (
						<>
							<div className='col-auto pt-3'>
								<Popovers trigger='hover' title='Режим разработки'>
									<Checks
										type='switch'
										checked={isDev}
										onChange={(event) => setIsDev(event.target.checked)}
									/>
								</Popovers>
							</div>
							<div className='col-auto'>
								<Popovers
									trigger='click'
									desc={<ReactSelect name='selectUser' onSetValue={onUserSelect} />}>
									<Button size='lg' icon='DeveloperMode' title='Зайти под пользователем' />
								</Popovers>
							</div>
						</>
					) : null
				}
				afterChildren={auth.user ? <CommonHeaderChat /> : null}
			/>
		</Header>
	);
};

export default DefaultHeader;
