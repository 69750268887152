import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import { RequireAuth, useAuth } from '../../contexts/authContext';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	const auth = useAuth();
	return (
		<Routes>
			{contents.map((page) =>
				// eslint-disable-next-line no-nested-ternary
				page.auth ? (
					// eslint-disable-next-line no-nested-ternary
					page.isAdmin ? (
						auth.user?.user.isAdmin ? (
							<Route
								key={page.path}
								path={page.path}
								element={<RequireAuth>{page.element}</RequireAuth>}
								exact={page.exact}
							/>
						) : (
							<Route path={page.path} key={page.path} element={<PAGE_404 />} />
						)
					) : (
						<Route
							key={page.path}
							path={page.path}
							element={<RequireAuth>{page.element}</RequireAuth>}
							exact={page.exact}
						/>
					)
				) : (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route key={page.path} {...page} />
				),
			)}
			<Route path='*' key='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
