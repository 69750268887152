import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			x='0'
			y='0'
			enableBackground='new 0 0 315 310.1663818'
			version='1.1'
			viewBox='0 0 315 310.166'
			xmlSpace='preserve'>
			<g fill='#f5a3e5'>
				<path d='M261.446 174.674c-44.543 21.12-52.045 48.257-53.976 60.96-4.853 31.865-25.998 50.014-55.314 54.867-25.972 4.308-40.551-.825-54.7-6.902 21.863 16.663 49.173 26.567 78.76 26.567 71.829 0 130.064-58.235 130.064-130.063 0-11.538-1.51-22.754-4.333-33.401-8.294 12.405-25.942 21.065-40.5 27.972zM59.325 237.073s3.724-7.372 2.663-9.836c-10.828-25.157-12.732-53.44-7.612-80.189 4.965-25.941 16.264-47.754 30.76-69.753 2.145-3.255 2.263-4.438 1.71-7.397-1.707.783-3.44 1.551-5.064 2.376-29.587 15.054-54.05 37.511-69.575 63.88-12.751 21.665-15.821 56.329-7.749 71.828 8.988 17.258 24.81 26.766 46.548 28.747 2.749.247 8.318.353 8.32.344zM115.859 63.596c22.152-6.274 45.388-9.533 68.28-7.706 24.629 1.97 48.633 11.18 68.257 26.191 20.64 15.789 38.78 32.45 46.876 57.146a151.15 151.15 0 002.675 7.475c8.517-12.727 13.222-27.855 13.048-44.395-.371-39.789-26.047-78.24-62.667-93.84-31.024-13.22-67.57-10.25-98.148 3.987-27.024 12.593-49.624 33.329-67.334 57.444 2.862 1.252 8.652.348 12.625-1.064 5.38-1.913 10.85-3.67 16.388-5.238z' />
			</g>
			<path
				fill='#ee6dd6'
				d='M157.87 291.943c29.315-4.853 50.46-23.002 55.313-54.868 1.931-12.702 9.434-39.838 53.976-60.958 14.56-6.908 26.494-17.01 34.788-29.415-6.74-19.193-32.061-48.48-53.206-53.308-25.874-5.892-58.012-.157-74.899 17.695-13.989 14.807-18.57 37.685-26.32 55.561-7.6 17.604-24.363 38.452-41.546 50.04-15.016 10.118-27.677 20.249-46.651 20.383.148.303.28.613.43.915 5.406 10.847 12.367 20.931 20.539 29.878 5.238 5.733 10.983 11.027 17.162 15.733 15.35 8.22 34.44 12.652 60.414 8.344z'
			/>
			<path
				fill='#eb4bcc'
				d='M178.222 50.287c-7.925-.005-15.852.657-23.777 1.627-11.97 1.46-23.83 3.838-35.515 6.759-10.96 2.887-21.773 6.497-32.084 11.225a230.47 230.47 0 00-6.698 9.606 237.24 237.24 0 00-8.607 14.116c-4 7.11-7.63 14.45-10.806 21.959-4.007 9.476-7.29 19.226-9.68 29.137-2.472 10.259-3.986 20.69-4.352 31.17-.312 8.945.125 17.957 1.573 26.906 1.19 7.367 3.067 14.691 5.772 21.902 1.204 3.208 1.683 4.594 3.093 7.872a58.339 58.339 0 002.184 4.507c18.974-.134 37.772-6.394 52.788-16.512 17.184-11.588 33.947-32.436 41.548-50.04 7.749-17.877 12.33-40.755 26.319-55.561 16.886-17.852 44.865-20.254 70.74-14.36 21.144 4.827 44.244 28.275 51.227 46.102a11.414 11.414 0 00-.153-.43c-.03-.08-.06-.158-.088-.24-.739-2.106-1.188-4.386-1.881-6.501a128.888 128.888 0 00-8.898-20.785 130.348 130.348 0 00-16.98-24.46 130.85 130.85 0 00-22.014-19.93 129.97 129.97 0 00-26.161-14.516 129.375 129.375 0 00-29.42-8.22c-6.041-.937-12.085-1.329-18.13-1.333z'
			/>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Logo.defaultProps = {
	width: 'auto',
	height: 'auto',
};

export default Logo;
