import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import { useAuth } from '../../contexts/authContext';
// import useFetch from '../../hooks/useFetch';
// import notification from '../../common/function/notification';

const Page = forwardRef(({ children, className, container, ...props }, ref) => {
	// const auth = useAuth();
	// const { fetchData } = useFetch();

	// useEffect(() => {
	// 	const controller = new AbortController();
	// 	let interval;

	// 	const intervalFetch = () => {
	// 		interval = setInterval(() => {
	// 			fetchData(`/notifications/?token=${auth.token}`).then((responseData) => {
	// 				if (responseData) {
	// 					if (Notification.permission === 'granted') {
	// 						// eslint-disable-next-line no-new
	// 						new Notification(responseData.title, {
	// 							body: responseData.content,
	// 						});
	// 					} else {
	// 						notification({
	// 							title: responseData.title,
	// 							text: responseData.content,
	// 							icon: responseData.icon,
	// 							color: responseData.value,
	// 						});
	// 					}
	// 				}
	// 			});
	// 		}, 5 * 60 * 1000);
	// 	};

	// 	if (auth.token) {
	// 		if (Notification.permission === 'default') {
	// 			Notification.requestPermission().then(() => {
	// 				intervalFetch();
	// 			});
	// 		} else {
	// 			intervalFetch();
	// 		}
	// 	}
	// 	return () => {
	// 		controller.abort();
	// 		if (interval) {
	// 			clearInterval(interval);
	// 		}
	// 	};
	// }, [auth.token, fetchData]);

	return (
		<div
			ref={ref}
			className={classNames('page', className, {
				[`container${typeof container === 'string' ? `-${container}` : ''}`]: container,
			})}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
	);
});
Page.propTypes = {
	children: PropTypes.node.isRequired,
	container: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.oneOf([null, 'sm', 'md', 'lg', 'xl', 'xxl', 'fluid']),
	]),
	className: PropTypes.string,
};
Page.defaultProps = {
	container: 'xxl',
	className: null,
};

export default Page;
